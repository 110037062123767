<template>
  <Header title="Daftar Akun" link1="Akuntansi" link2="Daftar Akun"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <h5 class="text-secondary d-inline">Daftar Kode Akun</h5>
            </div>
            <div class="col-md-8">
              <div class="float-end">
                <button class="btn btn-circle btn-gradient" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Akun</button>
              </div>
              <div class="float-end" style="margin-right: 10px;">
                <button class="btn btn-circle btn-success" @click="exportAccount()"> <ion-icon name="cloud-download-outline"></ion-icon> Ekspor Akun</button>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-md-6">
              <!-- <div class="input-group mb-3">
                <input type="text" v-model="search" class="form-control" placeholder="Pencarian" aria-label="Pencarian">
                <span class="input-group-text" id="basic-addon2"> <ion-icon name="search"></ion-icon> </span>
              </div> -->
              <div class="input-group mb-3">
                <input type="text" v-model="param.keyword" class="form-control" placeholder="Pencarian" aria-label="Pencarian">
                <button type="button" class="btn btn-primary mx-1" href="javascript:void(0)" @click="loadData('refresh')" ><ion-icon name="search-outline"></ion-icon> Cari</button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input type="file" class="form-control" id="file" ref="file" v-on:change="handleFileUpload()">
                <button class="btn btn-primary" type="button" v-on:click="importAccount()"> <ion-icon name="cloud-upload-outline"></ion-icon> Impor Akun</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="d-inline">
                <label for="">Tampilkan :</label>
                <select @change="loadData('refresh')" v-model="param.limit" class="d-inline text-primary form-select form-select-noborder" >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="float-end">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPage"
                  :range-size="1"
                  @update:modelValue="onPageChanged" />
              </div>
            </div>
          </div>
          <div class="d-flex d-none topbar">
            <button type="button" class="btn btn-sm btn-link-gray text-danger" @click="deleteSelectedAccount()">Delete Selected <ion-icon name="trash-outline"></ion-icon></button>
          </div>
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th width="10%">
                  <input class="form-check-input check-all" type="checkbox" @change="checkAll()">
                </th>
                <th>No</th>
                <th>Akun Induk</th>
                <th>Kode Akun</th>
                <th>Nama Akun</th>
                <th>Status</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in filteredList" :key="account.id">
                <td>
                  <input class="form-check-input check-one" :data-index="index" type="checkbox" @change="checkOne()">
                </td>
                <td>{{ index+1 }}</td>
                <td>{{ account.parent_id }}</td>
                <td>{{ account.id }}</td>
                <td>{{ account.name }}</td>
                <td><span v-if="account.status == 1" class="label label-success fw-bold">Aktif</span></td>
                <td>
                  <button type="button" class="btn btn-sm btn-link-gray" href="#" @click="edit(index, 'active')">Edit <ion-icon name="create-outline"></ion-icon></button>
                  <button type="button" class="btn btn-sm btn-link-gray" @click="deleteAccount(index, account)">Delete <ion-icon name="trash-outline"></ion-icon></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div id="accountModal" class="modal" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Tambah Kode Akun</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3">
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Akun Induk</label>
              <input placeholder="Akun Induk" v-model="keywordOption" class="text-primary form-select" type="text" autocomplete="off" @input="getSuggestions(keywordOption, 'modal')" @focus="modal = true">
              <div v-if="accountOptions.length > 0 && autocomplete == 'modal' && keywordOption.length > 3">
                <ul class="container-autocomplete">
                    <li class="item-autocomplete" v-for="account in accountOptions" :key="account.id" @click="setSearch(account)">{{account.id}} - {{ account.name }}</li>
                </ul>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Kode Akun</label>
              <input type="text" class="form-control" v-model="data.id" required>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Nama Akun</label>
              <input type="text" class="form-control" v-model="data.name" required>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Tipe Akun</label>
              <select class="form-select" v-model="data.acc_account_type_id" required>
                <option value="">-- Pilih Tipe --</option>
                <option
                  v-for="type in account_types"
                  :key="type.id"
                  :label="type.name"
                  :value="type.id">
                </option>
              </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Keterangan</label>
              <textarea class="form-control text-secondary" rows="2" v-model="data.description"></textarea>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Status</label>
              <div class="form-checkbox">
                <input class="form-check-input" type="checkbox" v-model="data.status">
                <span> Aktif</span>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
          <button type="button" class="btn btn-primary" @click="save()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
          <!-- <button type="button" class="btn btn-success"> <ion-icon name="color-wand-outline"></ion-icon> Simpan dan Generate</button> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script language="ts" src="./scripts/account.ts"></script>
