// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import Header from '@/components/Header.vue'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import { accountFactory } from '@/http/account'

@Options({
  components: {
    Header,
    VPagination
  },
  data() {
    return {
      accounts: [],
      accountOptions: [],
      account_types: [],
      totalAccount: 0,
      search: '',
      data: {
        old_id: '',
        id: '',
        parent_id: '',
        acc_account_type_id: '',
        name: '',
        description: '',
        status: true
      },
      param: {
        keyword: "",
        offset: 0,
        limit: 20,
        order_by: "desc",
        order_at: "created_at",
      },
      currentPage: 1,
      totalPage: 1,
      state: '',
      keywordOption: '',
      autocomplete: '',
      file: '',
    }
  },
  watch: {
    '$route.query': {
      handler: 'onPageQueryChange',
      deep: true
    }
  },
  methods: {
    onPageChanged(page:any) {
      if (page) {
        this.currentPage = page
      }
      page = Math.round(this.currentPage)
      if (this.totalPage >= page) {
        this.$router.push({ query: { page } })
      }
    },
    onPageQueryChange() {
      this.param.offset = (this.currentPage - 1) * parseInt(this.param.limit)
      this.loadData()
    },
    save: function() {
      if (this.data.name == '') {
        swal("Nama akun tidak boleh kosong", "", "warning")
        return
      }
      if (this.state == 'add') {
        // this.data.status = (this.data.status) ? 1 : 0,
        Promise.all([
          accountFactory.create(this.data),
        ]).then(results => {
          if (results[0] != false) {
            swal("Sukses menyimpan akun", "", "success")
            this.accountModal.hide()
            this.loadData()
          } else {
            swal("Gagal menyimpan akun", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan akun", "", "error")
          console.log(e)
        })
      } else {
        var data = {
          id: this.data.id,
          name: this.data.name,
          parent_id: this.data.parent_id,
          acc_account_type_id: this.data.acc_account_type_id,
          description: this.data.description,
          status: (this.data.status) ? 1 : 0
        }
        Promise.all([
          accountFactory.update(this.data.old_id, data),
        ]).then(results => {
          if (results[0] != false) {
            swal("Sukses menyimpan akun", "", "success")
            this.accountModal.hide()
            this.loadData()
          } else {
            swal("Gagal menyimpan akun", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan akun", "", "error")
          console.log(e)
        })
      }
    },
    edit: function(index: any, type: any) {
      // open modal with selected data
      this.accountModal.show()
      var account = this.accounts[index]
      var data = {
        old_id: account.id,
        id: account.id,
        parent_id: account.parent_id,
        acc_account_type_id: account.acc_account_type_id,
        name: account.name,
        description: account.description,
        status: (account.status == 1) ? true : false,
      }
      this.keywordOption = account.parent_id
      this.data = data
      this.state = 'edit'
    },
    add: function() {
      this.accountModal.show()
      this.keywordOption = ''
      this.data.id = ''
      this.data.acc_account_type_id = ''
      this.data.parent_id = ''
      this.data.name = ''
      this.data.description = ''
      this.data.status = true
      this.state = 'add'
    },
    exportAccount: function() {
      Promise.resolve(accountFactory.exportAccount())
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadData: function(type:any) {
      if (type === 'refresh') {
        this.onPageChanged(1)
      }
      var param = {
        keyword: this.param.keyword,
        offset: this.param.offset,
        limit: this.param.limit,
        order_by: "desc",
        order_at: "created_at"
      }
      Promise.all([
        accountFactory.get(param),
      ]).then(results => {
        this.accounts = results[0].data.accounts
        this.account_types = results[0].data.account_types
        this.totalAccount = results[0].data.total_rows

        var limit = parseInt(this.param.limit)
        var totalPage = Math.round((this.totalAccount + limit - 1) / limit)
        if ((totalPage * limit - this.totalAccount) >= limit) {
          totalPage = Math.round(this.totalAccount / limit)
        }
        this.totalPage = totalPage
      }).catch((e) => {
        swal("Gagal load data", "", "error")
        console.log(e)
      })
    },
    async getSuggestions(keyword: string, type: string) {
      if (keyword.length >= 3) {
        Promise.resolve(accountFactory.search(keyword))
        .then(result => {
          this.accountOptions = result.data.accounts
          this.autocomplete = type
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.accountOptions = []
        })
      } else {
        this.accountOptions = []
      }
    },
    setSearch(search:any){
      this.keywordOption = search.id
      this.data.parent_id = search.id
      this.accountOptions = [];
    },
    generateStringID: function() {
      var name = this.data.name
      var string_id = name.replace(/ /g,"_")
      this.data.id = string_id.toLowerCase()
    },
    importAccount: function () {
      this.imported = false

      if (this.file == '' && this.$refs.file.value != null) {
        Swal.fire("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      let formData = new FormData()
      formData.append('excel', this.file)
      Promise.resolve(accountFactory.importAccount(formData))
        .then((response) => {
          if (!response.data) {
            Swal.fire("Gagal melakukan request", "", "error")
            return
          }

          var total_saved = 0
          var label = 'success'
          if (response.data.saved_account)
            total_saved = response.data.saved_account.length

          var message = "Akun tersimpan: " + total_saved

          if (response.data.rejected_account) {
            message += "<br>Tidak Tersimpan:"
            label = 'warning'
            response.data.rejected_account.forEach(rejected => {
              message += "<br> " + rejected.reason
            })
          }

          Swal.fire({ html: message, icon: label, title: 'Akun Tersimpan!' })

          this.file = ''
          this.$refs.file.value = null
          this.loadData()

        }).catch((e) => {
          Swal.fire("Gagal melakukan request", "", "error")
          console.log(e)
        })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    deleteAccount: function(index: Number, account: any) {
      Swal.fire({
        title: "Konfirmasi!",
        html: '<b>Akun ' + account.id + " akan dihapus.</b><br><br>Apabila akun masih digunakan dalam transaksi, maka akan berpengaruh pada transaksi tersebut. Apakah Anda yakin ingin menghapus ?",
        icon: "question",
        showCancelButton: true,
        buttons: {
          cancel: true,
          confirm: true
        },
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        confirmButtonColor: "#3FA5E4",
        allowOutsideClick: false,
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm.isConfirmed) {

          Promise.all([
            accountFactory.delete(account.id),
          ]).then(results => {
            if (results[0].error == false) {
              Swal.fire("Sukses menghapus akun", "", "success")

              this.accounts.splice(index, 1)
            } else {
              Swal.fire("Gagal menghapus akun", "", "error")
            }
          }).catch((e) => {
            Swal.fire("Gagal menghapus akun", "", "error")
            console.log(e)
          })
        }
      })
    },
    checkAll: function() {
      var el = document.querySelector('.check-all')
      var els = document.querySelectorAll('.check-one')
      var topbar = document.querySelector('.topbar')
      //check if the checkbox is checked
      if (el.checked) {
        els.forEach((el:any) => {
          el.checked = true
        })
        topbar.classList.remove('d-none')
      } else {
        els.forEach((el:any) => {
          el.checked = false
        })
        topbar.classList.add('d-none')
      }
    },
    checkOne: function() {
      var els = document.querySelectorAll('.check-one')
      var topbar = document.querySelector('.topbar')
      var show_topbar = false
      els.forEach((el:any) => {
        if (el.checked) {
          show_topbar = true
        }
      })
      if (show_topbar) {
        topbar.classList.remove('d-none')
      } else {
        topbar.classList.add('d-none')
      }
    },
    deleteSelectedAccount: function() {
      var els = document.querySelectorAll('.check-one')
      var ids = []
      var indexs = []
      els.forEach((el:any) => {
        if (el.checked) {
          //get index of checked item
          var index = el.getAttribute('data-index')
          ids.push(this.accounts[index].id)
          indexs.push(index)
        }
      })    
      Swal.fire({
        title: "Konfirmasi!",
        html: '<b>' + indexs.length + " akun akan dihapus.</b><br><br>Apabila akun masih digunakan dalam transaksi, maka akan berpengaruh pada transaksi tersebut. Apakah Anda yakin ingin menghapus ?",
        icon: "question",
        showCancelButton: true,
        buttons: {
          cancel: true,
          confirm: true
        },
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        confirmButtonColor: "#3FA5E4",
        allowOutsideClick: false,
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm.isConfirmed) {
          Promise.all([
            accountFactory.bulkDelete(ids),
          ]).then(results => {
            if (results[0].error == false) {
              Swal.fire("Sukses menghapus akun", "", "success")
              var count = 0;
              indexs.forEach((index:any) => {
                this.accounts.splice(index-count, 1)
                count++
              })
            } else {
              Swal.fire("Gagal menghapus akun", "", "error")
            }
          }).catch((e) => {
            Swal.fire("Gagal menghapus akun", "", "error")
            console.log(e)
          })
          this.checkAll();
        }
      })
    },
  },
  async mounted () {

    var accountModal = document.getElementById('accountModal')
    if (accountModal != null) {
      this.accountModal = new bootstrap.Modal(accountModal)
    }
    this.accountModal.hide()
    this.loadData()
  },
  computed: {
    filteredList() {
      if (this.accounts.length > 0) {
        return this.accounts.filter((account:any) => {
          return account.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return []
    }
  }
})

export default class Account extends Vue {}
